<template>
    <div>
        <v-toolbar
            dense
            tabs
            color="primary darken-2"
            dark
            class="page_title"
        >
            <v-toolbar-title class="pa-0 pr-0 mt-1">
                <v-layout row >
                    <v-flex xs10 sm11 text-xs-left>
                        <a href="javascript: history.go(-1)" class="pa-0">
                            <v-btn flat fab small class="pa-0">
                                <i class="fas fa-arrow-left headline"></i>
                            </v-btn>
                        </a>
                        Our Products Services
                    </v-flex>
                    <v-flex xs2 sm1 text-xs-right>
                        <router-link :to="$store.state.close_url" class="pa-0 text-xs-right">
                            <v-btn flat fab small class="pa-0">
                                <i class="fas fa-close headline"></i>
                            </v-btn>
                        </router-link>
                    </v-flex>
                </v-layout>
            </v-toolbar-title>
        </v-toolbar>

        <v-container>
            <v-layout row>
                <v-flex xs12 md10 offset-md1>
                    <v-slide-y-transition mode="out-in">
                        <div>
                            <v-card color="white" style="border-radius: 10px;">
                                <v-card-text>
                                    <p class="mb-1">
                                        Introducing <b>PettyCash SAS</b>, the groundbreaking platform that is reshaping the world of financial management. Prepare to be captivated by our innovative approach to empowering both partners and clients alike.
                                    </p>
                                    <p class="mb-1">
                                        <b>Partners,</b> get ready to unlock a world of untapped potential as you embark on a journey towards unprecedented success. With our enticing commission structure, you'll be rewarded handsomely for every new client you bring on board. But that's not all – brace yourself for profit percentages based on investment, ensuring your earnings soar to unimaginable heights.
                                    </p>
                                    <p class="mb-1">
                                        <b>Clients,</b> get ready to experience a financial revolution like never before. Say goodbye to the complexities of saving and welcome hassle-free solutions that effortlessly grow your wealth. Need a quick loan? Look no further – our streamlined process ensures you receive the funds you need in record time. And when it comes to buying and selling, prepare for a seamless experience that puts you in control.
                                    </p>
                                    <p class="mb-1">
                                        <b>But that's not all</b> - we go beyond the basics to provide you with effective fundraising solutions, connecting you with the resources necessary to turn your dreams into reality. And with expert financial advice at your fingertips, you can confidently navigate any financial challenge that comes your way.
                                    </p>
                                    <p class="mb-1">
                                        <b>So why wait?</b> Join us today and redefine what it means to be financially empowered. Be part of the revolution that is transforming the way we manage our finances. Together, let's break barriers, shatter limitations, and embrace a future where financial success knows no bounds. <b>PettyCash SAS.</b> – the future of finance starts here.
                                    </p>
                                </v-card-text>
                            </v-card>
                            
                            <h2 class="mt-4">Our Services</h2>
                            <v-layout row wrap>
                                <v-flex xs12 sm6 md6 lg4 v-for="item in products_services" :key="item.id" class="pa-2">
                                    <v-hover>
                                        <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 12 : 4}`" style="border-radius: 5px; font-size: small" :to="item.link">
                                            <v-layout row >
                                                <v-flex xs8 class="text-xs-left">
                                                    <h5 class="subheading mt-4 pl-3 pt-4 pb-0 mb-0"><b>{{item.title}}</b></h5>
                                                </v-flex>
                                                <v-flex xs5  class="text-xs-left">
                                                    <v-btn  fab :color="item.color" class="mt-2 ml-3 mb-0 mr-2 white--text">
                                                        <i :class="item.icon"></i>
                                                    </v-btn>
                                                </v-flex>
                                            </v-layout>

                                            <v-card-title primary-title class="pb-0 mt-0 pt-1" style="color: #424242">
                                                <div>
                                                    <div class="caption mt-0 mb-0 pb-2">{{item.description}}</div>
                                                </div>
                                            </v-card-title>
                                        </v-card>
                                    </v-hover>
                                </v-flex>
                            </v-layout>
                        </div>
                    </v-slide-y-transition>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                products_services: [
                    {
                        id: '1',
                        title: 'Savings',
                        color: 'orange darken-2',
                        icon: 'fa-solid fa-piggy-bank fa-2x',
                        description: 'Saving and accumulating small amounts of money via our mobile platform (turning your piggy banks into a virtual mobile App)',
                        link: '/savings',
                        add_link: false
                    },
                    {
                        id: '2',
                        title: 'Market Place',
                        color: 'teal darken-2',
                        icon: 'fa-solid fa-shopping-cart fa-2x',
                        description: 'Petty cash Food Bank: Creating channels to supply food items within our network of clients mostly in food markets',
                        link: '/market_place',
                        add_link: false
                    },
                    {
                        id: '3',
                        title: 'Njangi',
                        color: 'blue darken-2',
                        icon: 'fa-solid fa-hands-helping fa-2x',
                        description: 'Start njangi groups with your friends and family members (Raising funds via Person to Person community)',
                        link: '/njangi',
                        add_link: false
                    },
                    {
                        id: '4',
                        title: 'Fundraising',
                        color: 'purple darken-2',
                        icon: 'fa-solid fa-hand-holding-usd fa-2x',
                        description: 'Invest, donate and follow-up in community projects strengthening communities around the world',
                        link: '/funding',
                        add_link: false
                    },
                    {
                        id: '5',
                        title: 'Monitor',
                        color: 'green darken-2',
                        icon: 'fa-solid fa-chart-line fa-2x',
                        description: 'A pettycash service that helps you track your expenses and income and proposes petty savings and investments',
                        link: '/monitor',
                        add_link: false
                    }
                ],
            }
        },

        mounted(){
            this.$store.commit('setCloseURL', "/")
        },

    }
</script>
